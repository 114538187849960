body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 'fit-content';
  background-color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  font-family: sans-serif;
  text-align: center;
}




.agencyData {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 30px;
}

.agencyDataBox {
  display: flex;
  flex : 1;
  min-width: fit-content;
}

.agencyDataBoxTitle {
  font-weight: bold;
  margin-right: 10px;
}

.drawerLink {
  text-decoration: none;
  color: #222222;
}


.errorMessage {
  white-space: pre-line;
}


.footer  {
  background-color:  white;
  padding: 10px;
  margin-top:calc(5% - 10);
  bottom: 0;
}